<template>
  <div>
    <b-row>
      <b-col cols="12" md="4">Payment ID</b-col>
      <b-col cols="12" md="6"><strong>{{payment.id}}</strong></b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="4">Student Name</b-col>
      <b-col v-if="payment.user.first_name !== 'undefined'" cols="12" md="6">
        <strong>{{payment.user.first_name || ""}} {{payment.user.last_name || ""}}</strong>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="4">Student NIC</b-col>
      <b-col cols="12" md="6"><strong>{{payment.user.nic || ""}}</strong></b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="4">Class Name</b-col>
      <b-col cols="12" md="6"><strong>{{payment.class_detail.name || ""}}</strong></b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="4">Paid For Month</b-col>
      <b-col cols="12" md="6"><strong>{{moment(payment.payment_month).format('YYYY-MM')}}</strong></b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: "payment_modal_info",
  props: [ 'payment' ],
  methods: {
    moment: function () {
      return moment();
    },
  }
}
</script>

<style scoped>

</style>