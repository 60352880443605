<template>
  <div>
    <Breadcrumbs title="Payments" main="Master Data"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body" v-if="loaded">

              <div class="row">
                <div class="m-t-6 col-12 col-md-6">
                  <b-input-group>
                    <b-form-input v-model="filter" placeholder="Type to Search" class="border btn-square" autofocus></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''" class="btn btn-square border" variant="primary">Clear</b-button>
                      <b-button @click="clearSearch" class="btn btn-square border" variant="light">Refresh</b-button>
                    </b-input-group-append>
                    <b-input-group-append>
                      <b-select @change="fetchData" v-model="recordStatus" :options="statusOptions" outlined></b-select>
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <div class="m-t-10 col-12 col-md-6 text-md-right">
                  <b-form-group label-cols="9" label="Per Page">
                    <b-form-select class="btn-square border text-center" v-model="perPage" :options="pageOptions"></b-form-select>
                  </b-form-group>
                </div>
              </div>

              <div class="table-responsive datatable-vue">
                <b-table
                    ref="table"
                    show-empty
                    selected-variant="success"
                    :items="tableRecords"
                    :fields="tableFields"
                    :filter="filter"
                    :current-page="1"
                    :per-page="perPage"
                    @filtered="onFiltered"
                    @row-selected="rowSelected"
                >
                  <template #cell(created_at)="row">
                    <p class="m-0">{{ new Date(row.item.created_at).toDateString() }}</p>
                    <p class="m-0">{{ new Date(row.item.created_at).toLocaleTimeString() }}</p>
                  </template>
                  <template #cell(payment_month)="row">
                    {{ format_payment_month(row.item.payment_month) }}
                  </template>
                  <template #cell(class_detail)="row">
                    <div>{{ row.item.class_detail.name }}</div>
                    <div>{{ row.item.user.first_name }} {{ row.item.user.last_name }} ( {{ row.item.user.nic }} )</div>
                    <div>{{ row.item.institute.name }} - {{ row.item.creator.first_name }}</div>
                    <div>
                      <p v-if="row.item.free_card_type === 1" class="badge badge-danger">Free Card</p>
                      <p v-else-if="row.item.free_card_type === 2" class="badge badge-warning text-white">Half Card</p>
                    </div>
                  </template>
                  <template #cell(settlement_id)="row">
                    {{ row.item.settlement_id ? 'YES' : 'NO' }}
                  </template>
                  <template #cell(action)="row" class="text-right">
                    <b-button v-show="checkUserPermissions(user,['cancel_payments'])" class="btn btn-block btn-square" variant="warning" @click="showCancelPaymentModal(row.index, row.item, $event.target)">Cancel</b-button>
                    <b-button v-show="checkUserPermissions(user,['delete_payments'])" class="btn btn-block btn-square" variant="danger" @click="showDeleteModal(row.index, row.item, $event.target)">Remove</b-button>
                  </template>
                </b-table>
              </div>

              <b-row >
                <b-col cols="4">
                  Records: <strong>{{ totalRows }}</strong>
                </b-col>
                <b-col cols="8" class="my-1" v-if="totalRows">
                  <b-pagination
                      align="right"
                      class="my-0"
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      pills
                  ></b-pagination>
                </b-col>
              </b-row>

              <!-- Begin Delete Modal -->
              <b-modal v-show="loaded" id="delete_modal" title="Delete" cancel-variant="default" ok-variant="danger" ok-title="Confirm" @hide="clearFormModals" @ok="submitDeleteModal">
                <h6 class="m-b-10">Please confirm removal of following record:</h6>
                <payment_modal_info :payment="selected" />
              </b-modal>
              <!-- End Delete Modal -->

              <!-- Begin Cancel Modal -->
              <b-modal v-show="loaded" id="cancel_modal" title="Cancel" cancel-variant="default" ok-variant="danger" ok-title="Confirm" @hide="clearFormModals" @ok="submitCancelModal">
                <h6 class="m-b-10">Please confirm cancellation of following record:</h6>
                <payment_modal_info :payment="selected" />
              </b-modal>
              <!-- End Cancel Modal -->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import payment_modal_info from "@/components/payment_modal_info";
import {checkUserPermissions} from '@/plugins/functions'
import { mapState } from 'vuex'
import moment from 'moment'
import CRUD_API from '@/services/crud'
export default {
  name: "payments",
  components: { payment_modal_info },
  data() {
    return {
      filter: '',
      totalRows: 0,
      perPage: 5,
      currentPage: 1,
      pageOptions: [5,10,25,50,100],
      tableRecords: [],
      selected: {
        class_detail: {},
        user: {}
      },
      loaded: false,

      api_base: '/api/backend/pages/payments',
      recordStatus: 0,
      tableFields: [
        // { key: 'id', label: 'ID', sortable: false },
        { key: 'payment_month', label: 'Payment Month', sortable: true },
        { key: 'created_at', label: 'Date / Time', sortable: true },
        { key: 'invoice_id', label: 'Invoice ID', sortable: true, class: 'text-right' },
        { key: 'class_detail', label: 'Class', sortable: true },
        { key: 'class_fee', label: 'Class Fee', sortable: false, class: 'text-right' },
        { key: 'discount', label: 'Discount', sortable: false, class: 'text-right' },
        { key: 'amount', label: 'Net Amount', sortable: false, class: 'text-right' },
        { key: 'settlement_id', label: 'Settled', sortable: false, class: 'text-center' },
        { key: 'action', label: 'Action', sortable: false, class: 'text-right text-capitalize' }
      ],

      statusOptions: [
        { value: 0, text: 'Active' },
        { value: 2, text: 'Cancelled' },
      ],

      // Form Modal
      form_modal_id: '',
      form_modal_title: '',
      form_modal_ok_variant: 'primary',
      form_modal_ok_title: '',
    }
  },
  watch: {
    currentPage() {
      this.fetchData()
    },
    perPage() {
      this.currentPage = 1
      this.fetchData()
    },
    filter() {
      this.currentPage = 1
      this.fetchData()
    }
  },
  mounted() {
    this.loaded  = false
    this.fetchData()
  },
  computed: mapState({ user: state => JSON.parse(state.auth.user) }),
  methods: {
    moment: function () {
      return moment();
    },
    checkUserPermissions,
    format_payment_month(value){
      if (value) {
        return moment(String(value)).format('YYYY-MM')
      }
    },
    onFiltered(filteredItems) {
      this.currentPage = 1
      this.items.count = filteredItems.length
    },
    rowSelected(item) {
      this.selected = item
    },
    clearSearch() {
      this.filter = ''
      this.currentPage = 1
      this.fetchData()
    },
    handlePageChange(value) {
      //alert(value)
      this.perPage = value
      this.fetchData()
    },
    handlePageSizeChange(value) {
      this.currentPage = value
      this.fetchData()
    },
    async fetchData() {
      await CRUD_API.index( this.api_base, {
        url: this.api_base,
        params: {
          per_page: this.perPage,
          page: this.currentPage,
          filter: this.filter,
          status: this.recordStatus
        }
      }).then(function(resp) {
        this.tableRecords = []
        //this.currentPage = resp.data.data.current_page
        //this.perPage = resp.data.data.per_page
        this.totalRows = resp.data.data.total
        this.tableRecords = resp.data.data.data
        this.loaded = true
      }.bind(this)).catch((err) => {
        console.log(err)
        this.loaded = false
        this.$toasted.error("Error occurred while retrieving data")
      }).finally(() => {
        // this.$refs.table.refresh()
      })
    },

    clearFormModals() { this.selected = []; },
    // Create Record Modal
    showCreateModal(button) {
      this.selected = { id: null, name: null, status: null }
      this.form_modal_title = "New Payment "
      this.form_modal_ok_title = 'Submit'
      this.$root.$emit('bv::show::modal', 'form_modal', button)
    },
    submitFormModal() {
      if (this.selected.id) {
        this.submitEditModal()
      } else {
        this.submitCreateModal()
      }
    },
    submitCreateModal() {
      CRUD_API.create(this.api_base, this.selected)
          .then((resp) => {
            console.log(resp)
            this.$toasted.success("Payment added successfully")
          })
          .catch((err) => {
            console.log(err)
            this.$toasted.error("Error occurred while saving data")
          })
          .finally(() => {
            this.fetchData()
          })
    },
    // Edit Record Modal
    showEditModal(index, item, button) {
      this.selected = item
      this.form_modal_title = "Edit Payment "
      this.form_modal_ok_title = 'Update'
      this.$root.$emit('bv::show::modal', 'form_modal', button)
    },
    submitEditModal(){
      CRUD_API.update(this.api_base, this.selected.id, this.selected)
          .then((resp) => {
            console.log(resp)
            this.$toasted.success("Payment updated successfully")
          })
          .catch((err) => {
            console.log(err)
            this.$toasted.error("Error occurred while updating data")
          })
          .finally(() => {
            this.fetchData()
          })
    },
    // Delete Record Modal
    showDeleteModal(index, item, button) {
      this.selected = item
      this.$root.$emit('bv::show::modal', 'delete_modal', button)
    },
    submitDeleteModal(){
      CRUD_API.delete(this.api_base, this.selected.id)
          .then((resp) => {
            console.log(resp)
            this.$toasted.success("Payment removed successfully")
          })
          .catch((err) => {
            console.log(err)
            this.$toasted.error("Error occurred while removing data")
          })
          .finally(() => {
            this.fetchData()
          })
    },

    showCancelPaymentModal(index, item, button) {
      this.selected = item
      this.$root.$emit('bv::show::modal', 'cancel_modal', button)
    },
    submitCancelModal(){
      CRUD_API.update(this.api_base, this.selected.id, {
        status: '2'
      }).then((resp) => {
        if(resp.data.success) {
          this.$toasted.success("Payment removed successfully")
        } else {
          this.$toasted.error("Payment removed successfully")
        }
      }).catch((err) => {
        console.log(err)
        this.$toasted.error("Error occurred while removing data")
      }).finally(() => {
        this.fetchData()
      })
    },
  }
}
</script>

<style scoped>

</style>
